export default {
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'secondary', 'tertiary'].includes(prop),
    },

    theme: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'secondary'].includes(prop),
    },

    title: {
      type: String,
    },

    body: {
      type: String,
    },

    buttons: {
      type: [Array, Object],
    },

    placeholder: {
      type: Object,
      required: true,
    },
  },
}
